<template>
    <div class="dashboard overflow-hidden">
        <div class="row mb-10">
            <div class="col-12">
                <TabInputSupport v-if="segment" :reception_id="reception_id" :segment="segment"></TabInputSupport>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
import TabInputSupport from "@/pages/Dashboard/TabInputSupport";
import DashboardService from "@/services/api/DashboardRepository";

export default {
    name: "Mobile",
    data() {
        return {
            reception_id: null,
            segment_id: null,
            patient: null,
            segment: null,
        };
    },
    components: {
        TabInputSupport
    },
    created() {
        this.reception_id = this.$route.query.reception_id;
        this.segment_id = this.$route.query.segment_id;
        this.getReceptionFlowSegments()
        this.getSegmentDetail()
        window.scroll({
            top: 0,
            behavior: "smooth"
        })
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),
        async getReceptionFlowSegments() {
            return await DashboardService.getReceptionFlowSegments(this.reception_id).then(res => {
                this.patient = res.data.data.patients;
            })
        },
        async getSegmentDetail() {
            return await DashboardService.getSegmentDetail(this.segment_id).then(res => {
                this.segment = res.data.data;
            })
        },
    }
}
</script>
