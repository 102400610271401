<template>
    <div class="dashboard-assistance-area row">
        <!-- タイトル -->
        <div class="col-12 pt-0 pb-1">
            <p v-if="!checkShowData" :class="classSegment(segment)" class="w-100 p-0 pl-1 m-0">ー</p>
            <TitleL v-else :segment="segment" :class="classSegment(segment)"></TitleL>
        </div>
        <!-- 支援情報 -->
        <ul class="list-group list-group-flush pl-0">
            <li v-if="!checkShowData" class="list-group-item">ー</li>
            <li v-else class="list-group-item">{{ infoPatient }}</li>
            <li class="list-group-item d-flex  justify-content-start">
                <span class="w-25">支援予定日</span>
                <span v-if="!checkShowData" class="pr-5">ー</span>
                <span v-else>{{ segment?.scheduled_support_date + (segment?.scheduled_support_time ? (" " + segment?.scheduled_support_time.substring(0,5)) : "") }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-start">
                <span class="w-25">支援形態</span>
                <span v-if="!checkShowData" class="pr-5">ー</span>
                <span v-else>{{ segment?.health_support_form?.support_form_div == codeStatusSupportFirst ? segment?.health_support_form?.support_form_name_disp_02 : segment?.health_support_form?.support_form_name_disp_01 }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-start">
                <span class="w-25">支援担当者</span>
                <span v-if="!checkShowData" class="pr-5">ー</span>
                <span v-else>
                  <span v-if="segment?.schedule_support_staff_user">{{ "[" + String(segment?.schedule_support_staff_user?.id).padStart(7, "0") + "]" + " " + segment?.schedule_support_staff_user?.user_name_disp }}</span>
                </span>
            </li>
            <li class="list-group-item d-flex justify-content-start border-bottom">
                <span class="w-25">支援場所</span>
                <span v-if="!checkShowData" class="pr-5">ー</span>
                <span v-else>{{segment?.scheduled_support_location?.item_name ?? "ー"}}</span>
            </li>
        </ul>

        <!-- 一時保存/確定 ラベル -->
        <div class="d-flex flex-row-reverse mt-2">
          <div class="tick rounded text-nowrap mr-5" v-if="getSupportProgressStatus == 30">一時保存</div>
          <div class="tick rounded text-nowrap mr-5" v-if="getSupportProgressStatus == 40">　確定　</div>
        </div>

        <!-- タブヘッダ -->
        <ul class="nav nav-tabs" id="pills-tab">
            <li class="nav-item">
                <button
                    class="nav-link active rounded-0"
                    id="first-support-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#first-support"
                    type="button"
                    role="tab"
                    aria-controls="first-support"
                >
                    支援入力
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link rounded-0"
                    id="personal-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#personal"
                    type="button"
                    role="tab"
                    aria-controls="personal"
                >
                    対象者
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link rounded-0"
                    id="test-category-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#test-category"
                    type="button"
                    role="tab"
                    aria-controls="test-category"
                >
                    検査項目
                </button>
            </li>
        </ul>
        <!-- タブコンテンツ -->
        <div class="tab-content" id="pills-tabContent">
            <!-- 支援入力フォーム -->
            <div class="tab-pane fade show active" id="first-support" role="tab" aria-labelledby="first-support-tab">
                <p v-if="!checkShowData" class="text-center mt-5">指定された支援は参照できません。</p>
                <ul v-else class="list-group list-group-flush pl-0">
                    <li class="list-group-item">
                      <L1
                          v-if="'L1' === block"
                          :roleSystem="roleSystem"
                          :nextSupportProgressStatus="nextSupportProgressStatus"
                          :classTagColor="classTagColor"
                          :showTabs="showTabs"
                          :isViewSupportInput="tabControl.support_input"
                          :reception="reception"
                          @reloadSegment="reloadSegment"
                          @onUpdatePatientInfo="updatePatientInfo"
                      ></L1>
                    </li>
                </ul>
            </div>
            <!-- 対象者情報 -->
            <div class="tab-pane fade show" id="personal" role="tab" aria-labelledby="personal-tab">
              <p v-if="!checkShowData" class="text-center mt-5">指定された対象者情報は参照できません。</p>
              <table v-if="patient && checkShowData" class="table table-responsive table-hover patient-table">
                <tr>
                  <td><p class="m-0 p-1 p1">対象者ID</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ String(patient.id).padStart(10, "0") }}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">氏名カナ</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_name_kana}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">氏名</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_name}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">生年月日</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.patient_birthday, "YYYY-MM-DD")}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">性別</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{ patient_gender[patient.patient_gender] }}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">保険者</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>[{{patient?.client_organizations?.client_organ_code}}] {{patient?.client_organizations?.client_organ_name}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">保険証番号</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.insured_card_number}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">保険証記号</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.insured_card_symbol}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">保険証枝番</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.insured_card_branch_code}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">利用券整理番号</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.use_ticket_ref_number}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">利用券有効期限</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.use_ticket_effective_date, "YYYY-MM-DD")}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">受診券整理番号</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.healthexam_ticket_ref_number}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">連絡先(携帯)</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_01}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">連絡先(自宅)</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_02}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">連絡先(職場)</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_03}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">連絡先(その他)</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_tel_04}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">メールアドレス</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_mail_01}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">郵便番号</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_postal_code}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">住所</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{patient.patient_addr_01}}</b></p></td>
                </tr>
                <tr>
                  <td><p class="m-0 p-1 p1">初回面接予定日時</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.scheduled_first_support_datetime, 'YYYY-MM-DD HH:mm')}}</b></p></td>
                </tr>

                <tr>
                  <td><p class="m-0 p-1 p1">取込日時</p></td>
                  <td class="align-baseline"><p class="m-0 p-1 p2"><b>{{formatDate(patient.healthexam_result_import_datetime, 'YYYY-MM-DD HH:mm:ss')}}</b></p></td>
                </tr>
              </table>
            </div>
            <!-- 検査項目 -->
            <div class="tab-pane fade show" id="test-category" role="tab" aria-labelledby="test-category-tab">
              <p v-if="!checkShowData" class="text-center mt-5">指定された対象者情報は参照できません。</p>
              <table v-else class="table table-responsive table-hover  healthexam-result-table">
                <tr
                    v-for="(item, index) in test_category"
                    :key="
                    item.patient_healthexam_results_id +
                    '-test_category-' +
                    index
                  "
                    :class="{
                    color_level_10: item.judge_level == 10,
                    color_level_20: item.judge_level == 20,
                    color_level_30: item.judge_level == 30,
                  }"
                >
                  <td>
                    <p class="m-0 p-1">{{ item.healthexam_item_name_disp }}</p>
                  </td>
                  <td class="align-baseline">
                    <p class="m-0 p-1">
                      <span v-if="item.healthexam_item_disp.length > 0"><b>{{ item.healthexam_item_disp }}</b></span>
                      <span v-else>ー</span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import mixins from "@/mixins"
import moment from "moment";
import DashboardService from "@/services/api/DashboardRepository";
import L1 from "@/pages/Dashboard/Components/L1Mobile";
import TitleL from "@/pages/Dashboard/Components/TitleL";
import localStorage from "@/common/localStorage";

import {
    CODE_GENDER,
    CODE_HEALTH_GUIDANCE_DIV,
    CODE_STATUS_SUPPORT, ROLE_CODE_USER,
    TOAST_TIME_OUT
} from "@/common/constants";
import {INTERNAL_SERVER_ERROR} from "@/constants";

export default {
    name: "InputSupport",
    data() {
        return {
            patient: null,
            health_support_flow: null,
            reception_id: null,
            reception: {},
            dataMobile: [],
            infoPatient: null,
            patientGender: null,
            healthGuidanceDiv: null,
            codeStatusSupportFirst: 10,
            codeStatusSupportA: 20,
            codeStatusSupportB: 30,
            codeStatusSupportFinal: 40,
            block: null,
            roleSystem: null,
            userPermission: null,
            systemUserRole: null,
            nextSupportProgressStatus: null,
            showTabs: "",
            tabControl: {},
            classTagColor: null,
            patient_gender: [],
            test_category: [],
            checkShowData: false,
            getSupportProgressStatus: null,
        };
    },
    components: {
        L1,
        TitleL
    },
    props: {
        segment: null
    },
    mounted() {
        this.reception_id = this.$route.query.reception_id;
        this.healthGuidanceReceptionDetail(this.reception_id);
    },
    watch: {
        reception_id(){
            this.reception_id = this.$route.query.reception_id;
            this.healthGuidanceReceptionDetail(this.reception_id);
        },

        segment(){
            this.getSupportProgressStatus = this.segment?.support_progress_status;
        }
    },
    async created() {
        this.reception_id = this.$route.query.reception_id;
        this.code_gender = await this.getCodeDefine(CODE_GENDER);
        this.code_health_guidance_div = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_DIV);
        this.userPermission = await this.getCodeDefine(ROLE_CODE_USER);
        this.patient_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        await this.healthGuidanceReceptionDetail(this.reception_id);

        this.getSupportProgressStatus = this.segment?.support_progress_status;
    },
    mixins: [
        mixins
    ],
    computed: {
        ...mapGetters({
            user: "common/getUser"
        })
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            setSegment: "dashboard/setSegment",
        }),
        handleBeforeDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = true;
            }
        },
        async healthGuidanceReceptionDetail(reception_id) {
            if(reception_id){
                return await DashboardService.healthGuidanceReceptionDetail(reception_id).then((res) => {
                    const data = res.data.data;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (data) {
                        // 参照可能チェック.
                        this.checkShowData = false;
                        if (this.segment.support_exec_div == this.INITIAL_SUPPORT) {
                            // 支援担当者ロールのみが設定されている場合.
                            if(this.isSupporter(true)){
                                const userId = this.user.id;
                                this.checkShowData = this.segment.scheduled_support_staff_id == userId || this.segment.support_staff_id == userId;
                            } else{
                                // システム管理者 or 事務局の場合、無条件で閲覧可能.
                                this.checkShowData = true;
                            }
                        }
                        this.reception = data;
                        this.patient = data.patients;
                        this.health_support_flow = data.health_support_flows[0];
                        DashboardService.getHealthResult(data.patient_id).then(
                            (resHealthResult) => {
                                this.test_category = [];
                                this.filterHasValueHealthexamResults(resHealthResult.data.data.diagnostic_list).map((x) => {
                                    this.test_category.push({
                                        judge_level: x.judge_level,
                                        healthexam_item_name_disp: x.healthexam_item_name_disp,
                                        healthexam_item_disp: x.healthexam_item_disp,
                                    });
                                });
                            }
                        );

                        // Processing gender display
                        for (const [key, value] of Object.entries(this.code_gender)) {
                            if (key == this.patient.patient_gender) {
                                this.patientGender = value;
                            }
                        }

                        // Processing health guidance div display
                        for (const [key, value] of Object.entries(this.code_health_guidance_div)) {
                            if (key == this.patient.health_guidance_div) {
                                this.healthGuidanceDiv = value;
                            }
                        }

                        // Show patient information
                        const lastYearSlice = String(data.contract_fiscal_year);
                        this.infoPatient = lastYearSlice.slice(-2)
                        + "年度"
                        + "　"
                        + "["
                        + (String(this.patient.id).padStart(10, "0") ?? " ")
                        + "]"
                        + (this.patient.patient_name_kana ?? " ")
                        // + "/"
                        // + (this.patient.patient_name ?? " ")
                        + "　"
                        + (this.patient.patient_birthday ?? " ")
                        + "/"
                        + (this.patientGender ?? " ")
                        + "　"
                        + (this.healthGuidanceDiv ?? " ");

                        const user = JSON.parse(localStorage.getInfo());
                        if (user) {
                            const handlePermissions = user.user_sys_auth_div;
                            const dataUserRole = handlePermissions.split(",");

                            for (const [key] of Object.keys(this.userPermission)) {
                                if (dataUserRole.includes(String(key))) {
                                    if (this.systemUserRole == null
                                    || this.systemUserRole > parseInt(key)) {
                                        this.systemUserRole = parseInt(key);
                                    }
                                    if (this.systemUserRole !== 8) {
                                        this.roleSystem = this.systemUserRole;
                                    }
                                }
                            }
                        }
                        this.setSegment(this.segment);

                        if(this.segment?.health_support_form?.support_form_div == this.codeStatusSupportFirst) {
                            this.block = "L1"
                            this.showTabs = "support_first"
                        } 
                    }
                });
            }
        },
        /**
         * 健診結果値が存在する項目のみでフィルタ
         */
        filterHasValueHealthexamResults(list) {
            if (!list) return list;
            const self = this;
            return list?.filter(function(x, index, array) {
                return self.hasHealthexamResultValue(x.healthexam_item_disp);
            });
        },
        /**
         * 健診結果値が存在する場合true
         */
        hasHealthexamResultValue(v) {
            const trimedVal = v?.trim();
            return trimedVal && !trimedVal.startsWith("ー");
        },
        formatDate(date, format = null) {
            if (date) {
                return moment(date).format(format ?? "YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },
        reloadSegment(getSupportProgressStatus){
            this.getSupportProgressStatus = getSupportProgressStatus;
        },
        updatePatientInfo(patient) {
            this.patient.patient_tel_01 = patient.patient_tel_01;
            this.patient.patient_tel_02 = patient.patient_tel_02;
            this.patient.patient_tel_03 = patient.patient_tel_03;
            this.patient.patient_tel_04 = patient.patient_tel_04;
            this.patient.patient_mail_01 = patient.patient_mail_01;
        }
    },
};
</script>
<style scoped lang="sass">
.patient-table, 
.healthexam-result-table
  font-size: 130%
  tr
      border-bottom: solid 1px
      border-color: #dee2e6
      border-bottom-width: 1px
      >*:first-child
        min-width: 160px
      >*:last-child
        word-break: break-all

#first-support,
#personal,
#test-category
    //height: 50vh
    //overflow-x: auto
.dashboard-assistance-area
  position: relative
  .tick
    position: absolute
    top: 22px
    right: 5px
    transform: rotate(-0deg)
    border: 1px solid rgb(0 0 255 / 50%)
    color: rgb(0 0 255 / 90%)
    background: rgba(255,255,255,0.7)
    padding: 2px
    border-radius: 5px
.list-group-item
  padding-right: 0
</style>
