<template>
    <v-card>
        <v-container>
            <v-card-title>確認</v-card-title>
            <div class="m-3">一時保存処理を実行します。よろしいですか？</div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="$emit('saveDraft')">{{ $t('common.ok') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogSaveDraft",
    props: {},
};
</script>

<style scoped>

</style>
