<template>
    <div>
    <div class="title-components p-0 pl-1">
        <SegmentTitle :segment="segment" />
    </div>
    <div v-if="checkTitle" class="title-components222 p-0 pl-1">
        ※現在の支援より前の支援が完了していません。
    </div>
    </div>
</template>
<style scoped>
.title-components222{
    background: white;
    color: red;
}
</style>
<script>
import mixins from "@/mixins";
import SegmentTitle from "@/components/dashboard/SegmentTitle";

export default {
    name: "TitleL",
    data() {
        return {
            checkTitle: false,
        }
    },
    props: {
        title: null,
        segment: null,
        healthSupportFlows: null,
        classTagColor: null,
    },
    components: {
        SegmentTitle
    },
    mixins: [
        mixins
    ],
    watch: {
        segment() {
            if(this.healthSupportFlows){
                if(
                    (this.healthSupportFlows[0].support_flow_status == 10 || this.healthSupportFlows[0].support_flow_status == 20)
             && this.classTagColor == "block-segment-white") {
                    this.checkTitle = true;
                }else{
                    this.checkTitle = false; 
                }
            }
        }
    },
    mounted() {
        if(this.healthSupportFlows){
            if(
                (this.healthSupportFlows[0].support_flow_status == 10 || this.healthSupportFlows[0].support_flow_status == 20)
             && this.classTagColor == "block-segment-white") {
                this.checkTitle = true;
            }else{
                this.checkTitle = false; 
            }
        }
    }
}
</script>