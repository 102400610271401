import Repository from "./Repository";
import { ADMIN_REPOSITORY, SUPPORT_FLOW } from "../../constants";
// import { CODE_SUPPORT_EXEC_DIV } from "@/common/constants";

export default {
    async weightOfDiagnosticResults(patient_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/weight-of-diagnostic-results/${patient_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getLeader(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/get-system-users/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getListSupportPerson() {
        try {
            return Repository.get(`${SUPPORT_FLOW}/support-person`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getFirstTimeSupport() {
        try {
            return await Repository.get("health-support-form/list-form-support",{support_form_div: "FIRST_SUPPORT"})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupportEvalCodesByCode(code = null) {
        try {
            return await Repository.get("health_support_eval_codes/list-or-by-code",{code})
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getOptionByCode({
        eval_item_code = null,
        eval_div,
        evaluator_div,
        health_guidance_period=4,
    }) {
        try {
            const optionExecutor = "0001000032";
            const smocking = "0001000100";
            const code_change_activity = "0001000050";
            const alcohol = "0001000110";
            const code_result = "0001000120";
            const wish_continue_support = "0001000240";
            const code_denied_support = "0001000251";

            const contact_way_priority_01 = "0001000270";
            const contact_way_priority_02 = "0001000271";
            const contact_way_priority_03 = "0001000272";

            var eval_item_code = [
                smocking, 
                code_change_activity, 
                alcohol, 
                code_result, 
                wish_continue_support, 
                code_denied_support, 
                optionExecutor,
                contact_way_priority_01,
                contact_way_priority_02,
                contact_way_priority_03,
            ];
            return await Repository.get("health-support-form/get-option-by-code",
                {
                    eval_item_code,
                    eval_div,
                    evaluator_div,
                    health_guidance_period,
                })
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getVersion(form, data) {
        try {
            return await Repository.post(`get-version/${form}`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    
    async segmentContinue(segment_id, action, data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-support-flow-segment/${segment_id}/update-segment-evals/${action}`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async list(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getSegmentDetail(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/detail/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
