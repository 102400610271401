export default function (){

    const TO_FULL_W_KANA_MAP = {
        "ｶﾞ": "ガ", "ｷﾞ": "ギ", "ｸﾞ": "グ", "ｹﾞ": "ゲ", "ｺﾞ": "ゴ",
        "ｻﾞ": "ザ", "ｼﾞ": "ジ", "ｽﾞ": "ズ", "ｾﾞ": "ゼ", "ｿﾞ": "ゾ",
        "ﾀﾞ": "ダ", "ﾁﾞ": "ヂ", "ﾂﾞ": "ヅ", "ﾃﾞ": "デ", "ﾄﾞ": "ド",
        "ﾊﾞ": "バ", "ﾋﾞ": "ビ", "ﾌﾞ": "ブ", "ﾍﾞ": "ベ", "ﾎﾞ": "ボ",
        "ﾊﾟ": "パ", "ﾋﾟ": "ピ", "ﾌﾟ": "プ", "ﾍﾟ": "ペ", "ﾎﾟ": "ポ",
        "ｳﾞ": "ヴ", "ﾜﾞ": "ヷ", "ｦﾞ": "ヺ",
        "ｱ": "ア", "ｲ": "イ", "ｳ": "ウ", "ｴ": "エ", "ｵ": "オ",
        "ｶ": "カ", "ｷ": "キ", "ｸ": "ク", "ｹ": "ケ", "ｺ": "コ",
        "ｻ": "サ", "ｼ": "シ", "ｽ": "ス", "ｾ": "セ", "ｿ": "ソ",
        "ﾀ": "タ", "ﾁ": "チ", "ﾂ": "ツ", "ﾃ": "テ", "ﾄ": "ト",
        "ﾅ": "ナ", "ﾆ": "ニ", "ﾇ": "ヌ", "ﾈ": "ネ", "ﾉ": "ノ",
        "ﾊ": "ハ", "ﾋ": "ヒ", "ﾌ": "フ", "ﾍ": "ヘ", "ﾎ": "ホ",
        "ﾏ": "マ", "ﾐ": "ミ", "ﾑ": "ム", "ﾒ": "メ", "ﾓ": "モ",
        "ﾔ": "ヤ", "ﾕ": "ユ", "ﾖ": "ヨ",
        "ﾗ": "ラ", "ﾘ": "リ", "ﾙ": "ル", "ﾚ": "レ", "ﾛ": "ロ",
        "ﾜ": "ワ", "ｦ": "ヲ", "ﾝ": "ン",
        "ｧ": "ァ", "ｨ": "ィ", "ｩ": "ゥ", "ｪ": "ェ", "ｫ": "ォ",
        "ｯ": "ッ", "ｬ": "ャ", "ｭ": "ュ", "ｮ": "ョ",
        "｡": "。", "､": "、", "ｰ": "ー", "｢": "「", "｣": "」", "･": "・"
    };
    
    const TO_HALF_W_KANA_MAP = {
        "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
        "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
        "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
        "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
        "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
        "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
        "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
        "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
        "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
        "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
        "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
        "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
        "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
        "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
        "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
        "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
        "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
        "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
        "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･"
    };

    const TO_HALF_W_KANA_REGEX = new RegExp("(" + Object.keys(TO_HALF_W_KANA_MAP).join("|") + ")", "g");
    const TO_FULL_W_KANA_REGEX = new RegExp("(" + Object.keys(TO_FULL_W_KANA_MAP).join("|") + ")", "g");

    const HALF_W_ALPHANUM_REGEX = new RegExp("[A-Za-z0-9]", "g");
    const HALF_W_SYMBOL_REGEX = new RegExp("[!-\/:-@\[-`\{-~]", "g");

    const FULL_W_ALPHANUM_REGEX = new RegExp("[Ａ-Ｚａ-ｚ０-９]", "g");
    const FULL_W_SYMBOL_REGEX = new RegExp("[！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]", "g");

    function toFullWidthString(str) {

        let convertedStr = str;

        try {
            // 半角英数を全角に変換
            convertedStr = convertedStr
                .replace(HALF_W_ALPHANUM_REGEX, function(match) {
                    return String.fromCharCode(match.charCodeAt(0) + 0xFEE0)
                });
            // 半角カタカナを全角に変換
            convertedStr = convertedStr
                .replace(TO_FULL_W_KANA_REGEX, function(match) {
                    return TO_FULL_W_KANA_MAP[match];
                })
                .replace(/ﾞ/g, "゛")
                .replace(/ﾟ/g, "゜");
            // 半角記号を全角に変換
            convertedStr = convertedStr
                .replace(HALF_W_SYMBOL_REGEX, function(match) {
                    return String.fromCharCode(match.charCodeAt(0) + 0xFEE0)
                });
            // 半角スペースを全角スペースに変換.
            convertedStr = convertedStr
                .replace(/ /g, "　");
                
            return convertedStr;
        } catch(e) {
            //例外エラーがおきたら、コンソールにログを出力する
            console.error("エラー：", e.message);
            return convertedStr;
        }
    };

    function toHalfWidthString(str) {

        let convertedStr = str;

        try {
            // 全角を半角英数に変換
            convertedStr = convertedStr
                .replace(FULL_W_ALPHANUM_REGEX, function(match) {
                    return String.fromCharCode(match.charCodeAt(0) - 0xFEE0)
                });
            // 全角カタカナを半角に変換
            convertedStr = convertedStr
                .replace(TO_HALF_W_KANA_REGEX, function(match) {
                    return TO_HALF_W_KANA_MAP[match];
                })
                .replace(/゛/g, "ﾞ")
                .replace(/゜/g, "ﾟ");
            // 全角を半角記号に変換
            convertedStr = convertedStr
                .replace(FULL_W_SYMBOL_REGEX, function(match) {
                    return String.fromCharCode(match.charCodeAt(0) - 0xFEE0)
                })
                .replace(/[‐－―]/g, "-") // ハイフンなど
                .replace(/[～〜]/g, "~") // チルダ
            ;
            // 全角スペースを半角スペースに変換.
            convertedStr = convertedStr
                .replace(/　/g, " ");
                
            return convertedStr;
        } catch(e) {
            //例外エラーがおきたら、コンソールにログを出力する
            console.error("エラー：", e.message);
            return convertedStr;
        }
    };

    return {
        toFullWidthString,
        toHalfWidthString,
    }
}